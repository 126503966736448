import Swiper from 'swiper/bundle';

export const initFeatureSlider = () => {
    const selector = '[data-features-slider]';
    const slider = document.querySelector(selector);
    if (!slider) return;

    const swiperSlider = new Swiper(selector, {
        slidesPerView: 1.2,
        speed: 400,
        spaceBetween: 20,
        loop: true,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        navigation: {
            nextEl: '[data-features-slider-next]',
            prevEl: '[data-features-slider-prev]',
        },
        breakpoints: {
            600: {
                slidesPerView: 2,
            },
            700: {
                slidesPerView: 3,
            },
            1024: {
                slidesPerView: 4,
            },
            1280: {
                slidesPerView: 5,
            },
            1440: {
                slidesPerView: 6,
                spaceBetween: 30,
            },
        },
    });
};
