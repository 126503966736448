import { isValidMail } from './helper-function/isValidMail';

export const initContactUsForm = () => {
    // VARS
    const contactUsForm = document.querySelector('[data-contact-us-form]');
    if (!contactUsForm) return;
    const userSubject = contactUsForm.querySelector('[data-input-subject]');
    const userEmail = contactUsForm.querySelector('[data-input-email]');
    const userMessage = contactUsForm.querySelector('[data-input-message]');
    const submit = contactUsForm.querySelector('[data-submit]');
    const successMessage = document.querySelector('[data-success-message]');

    const fieldsSelector = {
        userEmail: '[data-input-email]',
        userSubject: '[data-input-subject]',
        userMessage: '[data-input-message]',
        recaptcha: '[data-recaptcha]',
    };
    const classShowErrorMessage = 'is-visible-error';
    let isValidForm = true;

    // LISTENERS
    submit.addEventListener('click', handleOnSubmit, false);

    userSubject.addEventListener('focus', handleOnFocusFieldUserSubject, false);

    userEmail.addEventListener('focus', handleOnFocusFieldUserEmail, false);

    userMessage.addEventListener('focus', handleOnFocusFieldUserMessage, false);

    // HANDLERS
    function handleOnSubmit(event) {
        validateUserSubject();
        validateUserEmail();
        validateUserMessage();
        validateReCaptcha();

        if (!isValidForm) {
            event.preventDefault();
            isValidForm = true;
        } else {
            successMessage.classList.remove('hidden');
        }
    }

    function handleOnFocusFieldUserSubject() {
        hideErrorMessage(fieldsSelector.userSubject);
    }

    function handleOnFocusFieldUserEmail() {
        hideErrorMessage(fieldsSelector.userEmail);
    }

    function handleOnFocusFieldUserMessage() {
        hideErrorMessage(fieldsSelector.userMessage);
    }

    // FUNCTIONS
    function validateUserSubject() {
        hideErrorMessage(fieldsSelector.userSubject);

        if (userSubject.value.trim()) {
            hideErrorMessage(fieldsSelector.userSubject);
        } else {
            showErrorMessage(fieldsSelector.userSubject, 'required');
            isValidForm = false;
        }
    }

    function validateUserEmail() {
        hideErrorMessage(fieldsSelector.userEmail);

        if (!userEmail.value.trim()) {
            showErrorMessage(fieldsSelector.userEmail, 'required');
            isValidForm = false;
        } else if (!isValidMail(userEmail.value.trim())) {
            showErrorMessage(fieldsSelector.userEmail, 'invalid');
            isValidForm = false;
        } else {
            hideErrorMessage(fieldsSelector.userEmail);
        }
    }

    function validateUserMessage() {
        hideErrorMessage(fieldsSelector.userMessage);

        if (userMessage.value.trim()) {
            hideErrorMessage(fieldsSelector.userMessage);
        } else {
            showErrorMessage(fieldsSelector.userMessage, 'required');
            isValidForm = false;
        }
    }

    function validateReCaptcha() {
        hideErrorMessage(fieldsSelector.recaptcha);

        if (isValidReCaptcha()) {
            hideErrorMessage(fieldsSelector.recaptcha);
        } else {
            showErrorMessage(fieldsSelector.recaptcha, 'captcha');
            isValidForm = false;

            setTimeout(() => {
                hideErrorMessage(fieldsSelector.recaptcha);
            }, 5000);
        }
    }

    function hideErrorMessage(selector) {
        const field = document.querySelector(selector);
        const errors = field.closest('[data-input-wrapper]').querySelectorAll('[data-field-error]');

        field.classList.remove(classShowErrorMessage);
        [...errors].forEach((error) => {
            error.classList.remove(classShowErrorMessage);
        });
    }

    function showErrorMessage(selector, type) {
        const field = document.querySelector(selector);
        const error = field.closest('[data-input-wrapper]').querySelector(`[data-field-error="${type}"]`);

        field.classList.add(classShowErrorMessage);
        error.classList.add(classShowErrorMessage);
    }

    function isValidReCaptcha() {
        return Boolean(grecaptcha.getResponse());
    }
};
