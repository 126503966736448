import Swiper from 'swiper/bundle';

export const initTestimonialsSlider = () => {
    const selector = '[data-testimonial-slider]';
    const slider = document.querySelector(selector);
    if (!slider) return;

    const swiperSlider = new Swiper(selector, {
        slidesPerView: 1,
        speed: 400,
        spaceBetween: 20,
        loop: true,
        pagination: {
            el: '[data-testimonial-slider-pagination]',
            type: 'bullets',
            clickable: true,
        },
        breakpoints: {
            680: {
                slidesPerView: 2,
            },
            1280: {
                slidesPerView: 3,
                draggable: true,
            },
        },
    });
};
