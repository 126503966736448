import { getTarget } from '../helper-function/get-target';

export const initMobileFeatureMenu = () => {
    function MobileFeatureMenu() {
        // VARS
        const $this = this;
        const pricesPage = document.querySelector('[data-prices]');
        const toggle = document.querySelector('[data-mobile-features-menu-toggle]');
        const menuBar = document.querySelector('[data-mobile-features-menu]');
        const hamburger = document.querySelector('[data-hamburger]');
        const closeBtn = document.querySelector('[data-close-mobile-features-menu]');
        const activeClassPricesPage = 'prices--blur';
        const activeClassMenu = 'aside-feature-menu--open';
        const activeClassToggle = 'mobile-header-menu__button-lines--open';
        if (!pricesPage || !toggle) return;

        // LISTENERS
        toggle?.addEventListener(
            'click',
            function () {
                const hamburger = document.querySelector('.hamburger');
                hamburger.classList.contains('active') && hamburger.click();
                $this.toggle();
            },
            false,
        );

        closeBtn?.addEventListener(
            'click',
            function () {
                $this.close();
            },
            false,
        );

        document.addEventListener(
            'click',
            function (event) {
                if (
                    !getTarget(event, '[data-mobile-features-menu]') &&
                    !getTarget(event, '[data-mobile-features-menu-toggle]')
                ) {
                    $this.close();
                }

                if (getTarget(event, '[data-hamburger]')) {
                    if (hamburger.classList.contains('active')) {
                        disableScroll();
                    } else {
                        enableScroll();
                    }
                }
            },
            false,
        );

        // FUNCTIONS & METHODS
        $this.open = function () {
            menuBar.classList.add(activeClassMenu);
            toggle.classList.add(activeClassToggle);
            pricesPage.classList.add(activeClassPricesPage);
            window.disableScroll();
        };

        $this.close = function () {
            menuBar.classList.remove(activeClassMenu);
            toggle.classList.remove(activeClassToggle);
            pricesPage.classList.remove(activeClassPricesPage);
            window.enableScroll();
        };

        $this.toggle = function () {
            menuBar.classList.contains(activeClassMenu) ? $this.close() : $this.open();
        };
    }

    new MobileFeatureMenu();
};
