import { scrollTo } from '../tools/scrollTo';

export const initMobileMenu = () => {
    // VARS
    const hamburger = document.querySelector('[data-hamburger]');
    const header = document.querySelector('[data-header]');
    const linkAnchors = document.querySelectorAll('[data-link-anchor]');
    const homePage = document.querySelector('[data-home-page]');
    if (!hamburger) return;

    // LISTENERS
    hamburger.addEventListener('click', handleOnClickHamburger, false);

    [...linkAnchors].forEach((link) => {
        link.addEventListener('click', handleOnClickLinkAnchor, false);
    });

    window.addEventListener(
        'resize',
        function () {
            if (window.innerWidth > 780 && hamburger.classList.contains('active')) {
                hamburger.click();
            }
        },
        false,
    );

    // HANDLERS
    function handleOnClickHamburger() {
        toggleMobileMenu();
    }

    function handleOnClickLinkAnchor(event) {
        enableScroll();

        if (homePage) {
            event.preventDefault();
            closeMobileMenu();
            scrollToAnchor(this);
        }
    }

    // FUNCTIONS
    function toggleMobileMenu() {
        if (hamburger.classList.contains('active')) {
            closeMobileMenu();
            enableScroll();
        } else {
            openMobileMenu();
            disableScroll();
        }
    }

    function closeMobileMenu() {
        hamburger.classList.remove('active');
        header.classList.remove('open-menu');
    }

    function openMobileMenu() {
        hamburger.classList.add('active');
        header.classList.add('open-menu');
    }

    function scrollToAnchor(link) {
        const offset = window.innerWidth > 780 ? 0 : 50;
        scrollTo(`#${link.dataset.linkAnchor}`, offset);
    }
};
