import { deleteCookie } from '../helper-function/coockie/deleteCookie';
import { getCookie } from '../helper-function/coockie/getCookie';
import { setCookie } from '../helper-function/coockie/setCookie';

export const initBannersTimer = () => {
    const timer = document.querySelector('[data-timer-block]');
    if (!timer) return;

    const myTimerMD = document.querySelector('.my-timer-m-d'),
        myTimerMO = document.querySelector('.my-timer-m-o'),
        myTimerSD = document.querySelector('.my-timer-s-d'),
        myTimerSO = document.querySelector('.my-timer-s-o'),
        myTimerHD = document.querySelector('.my-timer-h-d'),
        myTimerHO = document.querySelector('.my-timer-h-o');

    startTimerParent();

    function startTimerParent() {
        const totalTime = Date.parse(new Date()) / 1000,
            timer = totalTime + 1200;

        if (
            !getCookie('spyzzzSaleTimer') ||
            getCookie('spyzzzSaleTimer') == null ||
            getCookie('spyzzzSaleTimer') - totalTime < 0
        ) {
            setCookie('spyzzzSaleTimer', timer, timer);
        }

        const totalSeconds = getCookie('spyzzzSaleTimer') - totalTime,
            hMain = Math.trunc(totalSeconds / 3600) % 24,
            mMain = Math.trunc(totalSeconds / 60) % 60,
            sMain = Math.trunc(totalSeconds) % 60;

        outputSpanTimer(String(hMain), myTimerHO, myTimerHD);
        outputSpanTimer(String(mMain), myTimerMO, myTimerMD);
        outputSpanTimer(String(sMain), myTimerSO, myTimerSD);

        function outputSpanTimer(textInto, currSpanT, currSpanO) {
            currSpanT.textContent = '';
            currSpanO.textContent = '';
            if (!textInto[1]) {
                if (!textInto) {
                    currSpanO.textContent = '0';
                    currSpanT.textContent = '0';
                } else {
                    currSpanO.textContent = '0';
                    currSpanT.textContent = textInto[0];
                }
            } else {
                currSpanT.textContent = textInto[1];
                currSpanO.textContent = textInto[0];
            }
        }

        if (!hMain && !mMain && !sMain) {
            deleteCookie('spyzzzSaleTimer');
            window.location.reload();
            return;
        }

        setTimeout(startTimerParent, 1000);
    }
};
